<template>
  <div class="ml-5 mr-5">
    <v-expansion-panels v-model="activePanels">
      <!-- Gong API Credentials Panel -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>
              {{ icons.mdiKeyVariant }}
            </v-icon>
            <strong>Gong API Credentials</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col sm="12">
              <span>You will need the Gong API access key and access key secret to connect to the Gong API.</span>
            </v-col>
            <v-col sm="12">
              <p v-if="selectedTask.apiDocs">
                <strong>{{ connector.name }} API Docs: <a
                  :href="selectedTask.apiDocs"
                  target="_blank"
                >Here</a></strong>{{ selectedTask.docsRequireLogin ? ' (Requires Login)' : '' }}
              </p>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                v-model="accessKey"
                :disabled="isEdit && !changeCredentials"
                :append-icon="showPasswords.accessKey ? icons.mdiEye : icons.mdiEyeOff"
                :type="showPasswords.accessKey ? 'text' : 'password'"
                label="Access Key"
                outlined
                dense
                placeholder="Access Key"
                pass
                @click:append="showPasswords.accessKey = !showPasswords.accessKey"
              ></v-text-field>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                v-model="secretAccessKey"
                :disabled="isEdit && !changeCredentials"
                :append-icon="showPasswords.secretAccessKey ? icons.mdiEye : icons.mdiEyeOff"
                :type="showPasswords.secretAccessKey ? 'text' : 'password'"
                label="Secret Access Key"
                outlined
                dense
                placeholder="Secret Access Key"
                @click:append="showPasswords.secretAccessKey = !showPasswords.secretAccessKey"
              ></v-text-field>
            </v-col>

            <v-row class="ml-1 mr-1 mb-1">
              <v-col
                sm="12"
                md="6"
              >
                <span><v-icon
                  color="warning"
                  left
                >{{ icons.mdiLock }}</v-icon>265-bit encryption</span>
              </v-col>
              <v-col sm="12">
                <v-alert
                  v-if="apiError"
                  type="error"
                >
                  Credentials Failed... Please Verify That The Credentials Are Correct
                </v-alert>
              </v-col>
            </v-row>
            <v-row class="mr-5 pb-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="isEdit && !changeCredentials"
                color="warning"
                @click="changeCredentials = true"
              >
                Edit Credentials
              </v-btn>
              <v-btn
                v-if="!isEdit || changeCredentials"
                :disabled="!accessKey || !secretAccessKey"
                :loading="isValidatingCredentials"
                color="primary"
                @click="checkAuthentication"
              >
                Validate Credentials
              </v-btn>
            </v-row>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Binding Panel -->
      <v-expansion-panel :disabled="!successfulCredentials && !isEdit">
        <v-expansion-panel-header>
          <span>
            <v-icon left>
              {{ icons.mdiTransitConnectionHorizontal }}
            </v-icon>
            <strong>Data Binding</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col sm="12">
              Using the destination fields from <strong>{{ connector.name }}</strong>, select a data field from Snowflake to be sent to the destination.
            </v-col>
            <v-col sm="12">
              <p v-if="selectedTask.apiDocs">
                <strong>{{ connector.name }} API Docs: <a
                  :href="selectedTask.apiDocs"
                  target="_blank"
                >Here</a></strong>{{ selectedTask.docsRequireLogin ? ' (Requires Login)' : '' }}
              </p>
            </v-col>

            <v-row
              justify="center"
              class="mt-5 mb-5"
            >
              <v-col
                sm="11"
              >
                <v-card
                  class="mb-3"
                  elevation="6"
                >
                  <v-card-text>
                    <v-row class="mb-2">
                      <v-col sm="12">
                        <v-alert
                          border="left"
                          dense
                          text
                          color="secondary"
                          type="info"
                        >
                          Note Regarding Gong Team Member Ids
                        </v-alert>
                      </v-col>
                      <span class="px-3">Gong requires that a call be associated to a team member's GongId (The id of the team member in Gong). If you do not have the Gong ID stored in Snowflake, we can attempt to get it from Gong using the team members unique email address.</span>

                      <v-col
                        lg="6"
                        md="12"
                      >
                        <v-checkbox
                          v-model="needGongIdCheckbox"
                          label="I need to get the team member's GongId from Gong"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        lg="6"
                        md="12"
                      >
                        <v-select
                          v-if="needGongIdCheckbox"
                          v-model="repIdIdentifier"
                          :items="dropDownSelection"
                          label="Select Team Members Email Field"
                          outlined
                          clearable
                        ></v-select>
                      </v-col>
                      <v-col
                        sm="12"
                      >
                        <span>*Important: Any call events sent to Gong containing a team member that does not have a Gong ID will fail. The team member must also have the <strong>Telephony system calls -> Import calls</strong> selected in the Gong settings or events will fail. </span>
                      </v-col>
                    </v-row>
                    <!-- Show Gong User List -->
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Gong Users
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <template>
                            <v-data-table
                              :headers="gongUserHeaders"
                              :items="getGongUsers"
                              :items-per-page="10"
                              class="elevation-1"
                            ></v-data-table>
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
          <!-- BINDING CARDS -->
          <v-row>
            <v-col
              v-for="binding in bindings"
              :key="binding.id"
              lg="6"
              sm="12"
            >
              <v-card :disabled="binding.header === 'Primary User ID' && repIdIdentifier">
                <div class="d-flex flex-column-reverse flex-md-row">
                  <div>
                    <v-card-title>
                      {{ binding.header }}
                    </v-card-title>
                    <v-card-text v-if="binding.headerDesc">
                      {{ binding.headerDesc }}
                    </v-card-text>
                    <div
                      v-for="value in binding.values"
                      :key="value.name"
                    >
                      <v-card-title v-if="value.text">
                        <h5>{{ value.text }}</h5>
                      </v-card-title>
                      <v-card-text class="d-flex align-center flex-wrap body-1">
                        <span class="text-sm"><strong v-if="value.required">Required</strong> {{ value.required && value.unique ? '|' : '' }} <strong v-if="value.unique">Must Be Unique</strong> {{ (value.unique && value.type || value.required && value.type) ? '|' : '' }} <span class="primary--text">{{ value.type }}</span></span>
                      </v-card-text>
                      <v-card-text>
                        {{ value.desc }}
                      </v-card-text>
                      <v-card-actions class="dense">
                        <v-row>
                          <v-col
                            v-if="!value.binding && requiredBindings.includes(binding.id + value.name)"
                            sm="12"
                          >
                            <v-alert
                              dense
                              text
                              type="error"
                            >
                              This field is required
                            </v-alert>
                          </v-col>
                          <v-col sm="12">
                            <v-select
                              v-model="value.binding"
                              :disabled="binding.header === 'Internal Party' && value.name === 'userId' && repIdIdentifier !== null"
                              :items="dropDownSelection"
                              :label="binding.header === 'Internal Party' && value.name === 'userId' && repIdIdentifier !== null ? 'Using User Email' : ''"
                              outlined
                              clearable
                              :required="value.required"
                              :rules="value.required ? [v => !!v || 'Field is required'] : []"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                      <v-card-text v-if="value.binding && !(binding.header === 'Internal Party' && value.name === 'userId')">
                        <strong>Examples:</strong> {{ sampleData(value.binding) }}
                      </v-card-text>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-3 my-5">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="validateBindings"
            >
              Next
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :disabled="!bindingsValidated">
        <v-expansion-panel-header>
          <span>
            <v-icon left>
              {{ icons.mdiContentSaveCog }}
            </v-icon>
            <strong>Save and Test Binding</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3 mb-5">
            <span>We can attempt to make a single API call with the provided data to ensure that the sync is successful. Would you like to continue?</span>
          </v-row>
          <v-row
            v-if="testRow && testRow.length > 0"
            class="ma-5"
          >
            <v-col sm="12">
              <span><strong> Sent The Following Record to Gong API</strong></span>
            </v-col>
            <template>
              <v-data-table
                dense
                :headers="headers"
                :items="testRow"
                :items-per-page="10"
                class="elevation-1"
              ></v-data-table>
            </template>
          </v-row>
          <v-row>
            <v-col
              v-if="testResponse.successfulLoads && testResponse.successfulLoads.length > 0"
              sm="12"
            >
              <v-alert
                text
                type="success"
              >
                Congratulations! The record above was ingested successfully to Gong. Check your Gong calls for more information.
              </v-alert>
            </v-col>
            <v-col
              v-if="testResponse.otherErrors && testResponse.otherErrors.length > 0"
              sm="12"
            >
              <v-alert
                text
                type="error"
              >
                You got an error when trying to load the test data:
                {{ testResponse.otherErrors }}
              </v-alert>
            </v-col>
            <v-col
              v-if="(!testRow || testRow.length < 1) && testResponse.successfulLoads.length < 1 && !testResponse.duplicateErrors && !testResponse.otherErrors"
              sm="12"
            >
              <v-alert
                text
                type="warning"
              >
                We were not able to validate a usable record to get a successful response back from Gong. You may want to go back to your data model and temporarily create a larger sample set using "LIMIT". This also could be due to not having users properly loaded into Gong or not having the correct call import permissions.
              </v-alert>
            </v-col>
            <v-col
              v-if="testResponse.duplicateErrors && testResponse.duplicateErrors.length > 0"
              sm="12"
            >
              <v-alert
                text
                type="warning"
              >
                We were not able to verify a successful Gong ingestion as the record above is a duplicate call (uniqueId) of a call already in Gong and therefore was rejected.
              </v-alert>
            </v-col>
            <v-col
              v-if="testResponse.missingUsers && testResponse.missingUsers.length > 0"
              sm="12"
            >
              <v-alert
                text
                type="warning"
              >
                The following users are not in Gong and calls cannot be added:
                {{ testResponse.missingUsers }}
              </v-alert>
            </v-col>
            <v-col
              v-if="testResponse.missingCredentials && testResponse.missingCredentials.length > 0"
              sm="12"
            >
              <v-alert
                text
                type="warning"
              >
                The following users do not have Gong permissions to import calls:
                {{ testResponse.missingCredentials }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            justify="space-around"
            class="mb-3"
          >
            <v-btn
              :loading="testLoading"
              color="primary"
              @click="testGongCallData"
            >
              Save and Test
            </v-btn>
            <v-btn
              :loading="testLoading"
              color="primary"
            >
              Save Only
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { readDoc, updateDoc, writeDoc } from '@/firestore'
import { encryptData } from '@/functions'
import store from '@/store'
import { mdiContentSaveCog, mdiEye, mdiEyeOff, mdiKeyVariant, mdiLock, mdiTransitConnectionHorizontal } from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { functions, httpsCallable } from '../../../firebaseApp'
import { gongBindingSendCallData } from '../binding-definitions/gong'

export default {
  props: {
    docId: {
      type: Number,
      default: 0,
    },
    snowBindingName: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    connector: {
      type: Object,
      default: () => {},
    },
    selectedTask: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Array,
      default: () => [],
    },
    sqlQuery: {
      type: String,
      default: '',
    },
    docStatus: {
      type: String,
      default: 'New',
    },
    editBindingObject: {
      type: Object,
      default: () => [],
    },
  },
  setup(props) {
    const dropDownSelection = computed(() => {
      let getHeaders = props.headers
      getHeaders = getHeaders.sort((a, b) => (a.text > b.text ? 1 : -1))

      return getHeaders
    })
    const bindings = ref([])
    const testRow = ref([])
    const testLoading = ref(false)
    const encryptedAccessKey = ref(null)
    const encryptedAccessKeySecret = ref(null)
    const form = ref(null)
    const rulesRequired = [v => !!v || 'Field is Required']
    const requiredBindings = ref([])
    const showPasswords = ref({ accessKey: false, secretAccessKey: false })
    const gongUserHeaders = [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Can Import Calls', value: 'canImportCalls' },
    ]
    const accessKey = ref(null)
    const secretAccessKey = ref(null)
    const activePanels = ref(0)
    const repIdIdentifier = ref(null)
    const needGongIdCheckbox = ref(false)
    const callId = ref(null)
    const successfulCredentials = ref(false)
    const apiError = ref(false)
    const gongUsers = ref([])
    const isValidatingCredentials = ref(false)
    const bindingsValidated = ref(false)
    const isEdit = ref(false)
    const changeCredentials = ref(false)
    const testResponse = ref({ successfulLoads: [], otherErrors: [], missingUsers: [], missingCredentials: [] })

    const buildEditData = () => {
      const bindingObject = props.editBindingObject
      isEdit.value = true
      activePanels.value = 1
      if (bindingObject.repIdIdentifier) {
        needGongIdCheckbox.value = true
        repIdIdentifier.value = bindingObject.repIdIdentifier
      }
      bindings.value = bindingObject.bindings
    }

    onMounted(() => {
      bindings.value = gongBindingSendCallData
      if (props.docStatus === 'Edit') {
        buildEditData()
      }
    })

    const setUserIdFromEmail = payload => {
      const findHeaderIndex = bindings.value.findIndex(i => i.header === 'Internal Party')
      const findValueIndex = bindings.value[findHeaderIndex].values.findIndex(i => i.name === 'userId')
      bindings.value[findHeaderIndex].values[findValueIndex].binding = payload
    }

    watch(repIdIdentifier, to => {
      if (to) setUserIdFromEmail('Using User Email')
      else setUserIdFromEmail(null)
    })

    const validateBindings = () => {
      const getBindings = bindings.value
      getBindings.forEach(binding => {
        binding.values.forEach(value => {
          if (value.required && !value.binding) {
            requiredBindings.value.push(binding.id + value.name)
          }
        })
      })
      if (requiredBindings.value.length === 0) {
        const payload = {
          collection: 'snowflakeBindings',
          docId: props.docId.toString(),
          data: { bindings: bindings.value },
        }
        if (repIdIdentifier) {
          payload.data.repIdIdentifier = repIdIdentifier.value
        } else {
          payload.data.repIdIdentifier = null
        }
        updateDoc(payload)
        bindingsValidated.value = true
        activePanels.value = 2
      }
    }

    const encryptCredentials = async () => {
      const promises = []
      promises.push(encryptData(accessKey.value))
      promises.push(encryptData(secretAccessKey.value))

      return Promise.all(promises)
    }

    const writeCredentials = async () => {
      const payload = {
        collection: 'snowflakeBindings',
        data: {
          modified: new Date(),
          id: props.docId,
          name: props.snowBindingName,
          query: props.sqlQuery,
          credentials: { accessKey: encryptedAccessKey.value, secretAccessKey: encryptedAccessKeySecret.value },
          taskId: props.selectedTask.id,
        },
        docId: props.docId.toString(),
      }
      if (props.docStatus === 'Edit' || successfulCredentials.value) {
        await updateDoc(payload)
      } else {
        payload.data.created = new Date()
        await writeDoc(payload)
      }
    }

    const checkAuthentication = async () => {
      // Encrypt and Save Credentials
      const encryptResponse = await encryptCredentials()
      encryptedAccessKey.value = encryptResponse[0].data
      encryptedAccessKeySecret.value = encryptResponse[1].data

      // Save Credentials
      apiError.value = false
      isValidatingCredentials.value = true
      try {
        const sendToFunction = httpsCallable(functions, 'callFunction')
        const payload = { accessKey: encryptedAccessKey.value, secretAccessKey: encryptedAccessKeySecret.value }
        const response = await sendToFunction({
          functionName: 'testGongConnection',
          payload,
        })
        console.log('response', response)
        if (response && response.data && (response.data.httpErrorCode || response.data.code)) {
          apiError.value = true
        } else {
          gongUsers.value = response
          activePanels.value = 1
          writeCredentials()
          successfulCredentials.value = true
        }
        isValidatingCredentials.value = false
      } catch (err) {
        const errorMessage = `Gong API Error: ${err}`
        console.log(errorMessage)
        isValidatingCredentials.value = false
        apiError.value = true
        throw errorMessage
      }
    }

    const getGongUsers = computed(() => {
      if (gongUsers && gongUsers.value && gongUsers.value.data) {
        let gongUserList = gongUsers.value.data

        gongUserList = gongUserList.map(m => ({
          name: `${m.firstName} ${m.lastName}`,
          email: m.emailAddress,
          canImportCalls: m.settings.telephonyCallsImported,
        }))

        return gongUserList
      }

      return []
    })

    const sampleData = field => [...new Set(props.rows.map(m => m[field]))]

    const getBindingObject = async () => {
      try {
        const response = await readDoc({
          account: store.state.accountProfile,
          collection: 'snowflakeBindings',
          docId: props.docId,
        })

        return response
      } catch (err) {
        const errorMessage = `Error Getting Binding Object: ${err}`
        throw errorMessage
      }
    }

    const testGongCallData = async () => {
      testResponse.value = {
        successfulLoads: [],
        otherErrors: [],
        missingUsers: [],
        missingCredentials: [],
        duplicateErrors: [],
        duplicatesRemoved: null,
      }
      testLoading.value = true
      const bindingObject = await getBindingObject()
      const sendToFunction = httpsCallable(functions, 'callFunction')
      const payload = {
        testCall: props.rows,
        account: store.state.accountProfile,
        bindingObject,
      }
      try {
        const response = await sendToFunction({
          functionName: 'sendGongCallData',
          payload,
        })
        console.log('REsponse', response)
        testResponse.value = response.data.results
        testRow.value = response.data.results.testRecord
        testLoading.value = false
      } catch (err) {
        console.log('Error: ', err)
        testLoading.value = false
        throw err
      }
    }

    return {
      testResponse,
      testRow,
      testLoading,
      testGongCallData,
      bindingsValidated,
      dropDownSelection,
      requiredBindings,
      form,
      rulesRequired,
      validateBindings,
      isValidatingCredentials,
      bindings,
      gongUserHeaders,
      getGongUsers,
      showPasswords,
      gongUsers,
      successfulCredentials,
      apiError,
      checkAuthentication,
      accessKey,
      secretAccessKey,
      activePanels,
      sampleData,
      callId,
      repIdIdentifier,
      needGongIdCheckbox,
      isEdit,
      changeCredentials,
      icons: {
        mdiContentSaveCog,
        mdiKeyVariant,
        mdiTransitConnectionHorizontal,
        mdiLock,
        mdiEye,
        mdiEyeOff,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  white-space: nowrap;
}
</style>
