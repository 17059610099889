import { mdiAccountMultipleOutline, mdiPhoneInTalkOutline } from '@mdi/js'

export default [
  { path: require('@/assets/images/logos/GoogleSheetsLogoLarge.png'), name: 'Google Sheets' },
  { path: require('@/assets/images/logos/GoogleAdsLogoLarge.png'), name: 'Google Ads' },
  { path: require('@/assets/images/logos/FacebookAdsLogoLarge.png'), name: 'Facebook Ads' },
  { path: require('@/assets/images/logos/YahooAdsLogoLarge.png'), name: 'Yahoo Ads' },
  { path: require('@/assets/images/logos/BingAdsLogoLarge.png'), name: 'Bing Ads' },
  {
    path: require('@/assets/images/logos/GongLogoLarge.png'),
    name: 'Gong',
    ingressTasks: [
      {
        task: 'Get Gong Users',
        id: 'GONG02',
        desc: 'Pull a list of all Gong users and their profiles',
        icon: mdiAccountMultipleOutline,
        apiDocs: 'https://app.gong.io/welcome/sign-in?targeturl=/settings/api/documentation',
        docsRequireLogin: true,
      },
      {
        task: 'Get Gong Calls',
        id: 'GONG03',
        desc: 'Pull a list of calls sent or created in Gong',
        icon: mdiPhoneInTalkOutline,
        apiDocs: 'https://app.gong.io/welcome/sign-in?targeturl=/settings/api/documentation',
        docsRequireLogin: true,
      },
    ],
    egressTasks: [
      {
        task: 'Send Call Data to Gong',
        id: 'GONG01',
        desc: 'Provide a list of calls and call data to send to Gong',
        icon: mdiPhoneInTalkOutline,
        apiDocs: 'https://app.gong.io/welcome/sign-in?targeturl=/settings/api/documentation',
        docsRequireLogin: true,
      },
    ],
  },
]
