<template>
  <v-card>
    <v-card-title>
      <v-icon
        left
        color="primary"
      >
        {{ icons.mdiCogTransfer }}
      </v-icon><span class="primary--text">{{ isEdit ? 'Edit Binding' : 'Snow Binding Creation' }}</span>
      <div v-if="currentTab > 0">
        <span class="primary--text ml-2">|</span>
        <strong><span class="primary--text ml-2">{{ snowBindingName }}</span></strong>
      </div>
    </v-card-title>

    <v-tabs
      v-model="currentTab"
      vertical
    >
      <v-img
        v-if="selectedConnector"
        contain
        max-height="200"
        max-width="200"
        :src="selectedConnector.path"
        :alt="selectedConnector.name"
      >
      </v-img>
      <v-tab :disabled="isEdit">
        <v-icon left>
          {{ icons.mdiTransitConnectionVariant }}
        </v-icon>
        Connector
      </v-tab>
      <v-tab :disabled="(!selectedConnector || !snowBindingName) && !isEdit">
        <v-icon left>
          {{ icons.mdiTableEdit }}
        </v-icon>
        Build Model
      </v-tab>
      <v-tab :disabled="!selectedConnector || !queryResults.length || queryChanged">
        <v-icon left>
          {{ icons.mdiTransitConnectionHorizontal }}
        </v-icon>
        Bind Dataset
      </v-tab>
      <!-- Select a Connector -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-alert
              border="left"
              colored-border
              text
              color="primary"
            >
              <span class="font-weight-bold">Select a Connector</span>
            </v-alert>
            <v-row

              class="mb-3 text-center text-caption font-weight-bold"
            >
              <div
                v-for="connector in connectors"
                :key="connector.name"
              >
                <v-card
                  class="ma-5 pb-3"
                  :style="selectedConnector && selectedConnector.name === connector.name ? 'border: 1px solid grey' : ''"
                  @click="selectedConnector = connector; selectedTask = null; ingressTaskSelectionIndex = null; egressTaskSelectionIndex = null"
                >
                  <v-img
                    height="300"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="connector.path"
                    :alt="connector.name"
                  >
                  </v-img>
                  <span>{{ connector.name }}</span>
                </v-card>
              </div>
            </v-row>
            <!-- Show Connector Task -->
            <v-expansion-panels
              v-if="selectedConnector"
              v-model="taskPanel"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span><v-icon left>{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon>Select a <strong>{{ selectedConnector.name }}</strong> Task <strong>{{ selectedTask ? ' - ' + selectedTask.task : '' }}</strong></span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panel v-if="selectedConnector.ingressTasks && selectedConnector.ingressTasks.length > 0">
                      <v-expansion-panel-header>
                        <span><v-icon left>{{ icons.mdiDatabaseArrowLeftOutline }}</v-icon>Get Data <strong>From {{ selectedConnector.name }}</strong></span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template>
                          <v-list
                            rounded
                          >
                            <v-list-item-group
                              v-model="ingressTaskSelectionIndex"
                              color="primary"
                            >
                              <v-list-item
                                v-for="item in selectedConnector.ingressTasks"
                                :key="item.id"
                                @click="egressTaskSelectionIndex = null; saveTaskSelection(item)"
                              >
                                <v-list-item-icon>
                                  <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.task"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="selectedConnector.egressTasks && selectedConnector.egressTasks.length > 0">
                      <v-expansion-panel-header>
                        <span><v-icon left>{{ icons.mdiDatabaseArrowRightOutline }}</v-icon>Send Data <strong>To {{ selectedConnector.name }}</strong></span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template>
                          <v-list
                            rounded
                          >
                            <v-list-item-group
                              v-model="egressTaskSelectionIndex"
                              color="primary"
                            >
                              <v-list-item
                                v-for="item in selectedConnector.egressTasks"
                                :key="item.id"
                                @click="ingressTaskSelectionIndex = null; saveTaskSelection(item)"
                              >
                                <v-list-item-icon>
                                  <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.task"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel :disabled="!selectedTask">
                <v-expansion-panel-header>
                  <span><v-icon left>{{ icons.mdiSquareEditOutline }}</v-icon>Snow Binding Name</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="snowBindingName"
                    label="Snow Binding Name"
                    outlined
                  >
                  </v-text-field>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="mt-5">
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!snowBindingName"
                color="primary"
                class="mr-5 mb-5"
                @click="currentTab = 1"
              >
                Next
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- Build SQL Data Model -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col>
                <v-alert
                  border="left"
                  colored-border
                  text
                  color="primary"
                >
                  <span class="font-weight-bold">Build a Data Model with Snowflake SQL</span>
                </v-alert>
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col
                v-if="selectedTask && selectedTask.apiDocs"
                sm="12"
              >
                <span>
                  <strong>{{ selectedConnector.name }} API Docs: <a
                    :href="selectedTask.apiDocs"
                    target="_blank"
                  >Here</a></strong>{{ selectedTask.docsRequireLogin ? ' (Requires Login)' : '' }}
                </span>
              </v-col>
              <v-expansion-panels v-if="selectedConnector && selectedConnector.name === 'Gong'">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span><v-icon left>{{ icons.mdiFileTableBox }}</v-icon><strong>{{ selectedConnector.name }} API Data Elements - {{ selectedTask && selectedTask.task ? selectedTask.task : '' }}</strong></span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template>
                      <v-data-table
                        dense
                        :headers="bindingHeaders"
                        :items="bindingData"
                        :items-per-page="10"
                      ></v-data-table>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-row>
              <v-textarea
                v-model="sqlQuery"
                label="SQL Query"
                outlined
                shaped
                height="400"
                font-size="26px"
              ></v-textarea>
            </v-row>
            <v-row>
              <v-col v-if="alert.message">
                <v-alert
                  :type="alert.type"
                  dense
                  text
                  :color="alert.type"
                  class="mb-0"
                >
                  {{ alert.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              class="justify-center"
            >
              <v-btn
                :disabled="!sqlQuery"
                :loading="queryLoading"
                color="primary"
                class="mr-5 mb-5"
                @click="validateQuery"
              >
                Validate Query
              </v-btn>
              <v-btn
                v-if="queryResults.length > 0"
                :disabled="queryChanged"
                color="info"
                class="mr-5 mb-5"
                @click="currentTab = 2"
              >
                {{ queryChanged ? 'Query Changed' : 'Results Look Good, Next' }}
              </v-btn>
            </v-row>
            <v-alert
              v-if="limitEnforced && (!alert || JSON.stringify(alert) === '{}')"
              dense
              text
              color="primary"
              type="info"
              class="mt-3"
            >
              {{ limitEnforced }}
            </v-alert>
            <template
              v-if="headers.length > 0 && queryResults.length > 0 && !queryError"
              class="mt-3"
            >
              <v-data-table
                :headers="headers"
                :items="queryResults"
                :items-per-page="10"
                class="elevation-1"
              ></v-data-table>
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col>
                <v-alert
                  border="left"
                  colored-border
                  text
                  color="primary"
                >
                  <span class="font-weight-bold">Bind Query Results with {{ selectedConnector && selectedConnector.name ? selectedConnector.name : 'Connector' }}</span>
                </v-alert>
              </v-col>
            </v-row>
            <component
              :is="selectedTask.id.toLowerCase()"
              v-if="selectedTask && selectedTask.id"
              :doc-id="docId"
              :snow-binding-name="snowBindingName"
              :headers="headers"
              :connector="selectedConnector"
              :selected-task="selectedTask"
              :rows="queryResults"
              :sql-query="sqlQuery"
              :doc-status="isEdit ? 'Edit' : 'New'"
              :edit-binding-object="editBindingObject"
            >
            </component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import { readDoc } from '@/firestore'
import { snowflakeQuery } from '@/snowflake'
import { useRouter } from '@core/utils'
import {
  mdiAccessPoint,
  mdiAccountOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCogTransfer,
  mdiDatabaseArrowLeftOutline,
  mdiDatabaseArrowRightOutline,
  mdiFileTableBox,
  mdiLockOutline,
  mdiSquareEditOutline,
  mdiTableEdit,
  mdiTransitConnectionHorizontal,
  mdiTransitConnectionVariant,
} from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { gongBindingSendCallData } from './binding-definitions/gong'
import connectors from './connectors'
import gong01 from './connectors/Gong01.vue'

export default {
  components: {
    gong01,
  },
  setup() {
    const isEdit = ref(false)
    const { route } = useRouter()
    const docId = ref(Date.now())
    const taskPanel = ref(0)
    const ingressTaskSelectionIndex = ref(null)
    const egressTaskSelectionIndex = ref(null)
    const snowBindingName = ref(null)
    const snowBindingTask = ref(null)
    const alert = ref({})
    const headers = ref([])
    const queryResults = ref([])
    const limitEnforced = ref(false)
    const queryError = ref(null)
    const queryLoading = ref(false)
    const currentTab = ref(0)
    const sqlQuery = ref('')
    const selectedConnector = ref(null)
    const selectedTask = ref(null)
    const executedQuery = ref(null)
    const queryChanged = ref(true)
    const editBindingObject = ref(null)
    const bindingHeaders = [
      { text: 'Grouping', value: 'header', align: 'start' },
      { text: 'Object', value: 'name', align: 'start' },
      { text: 'Required', value: 'required', align: 'start' },
      { text: 'Must Be Unique', value: 'unique', align: 'start' },
      { text: 'Data Type', value: 'type', align: 'start' },
      { text: 'Description', value: 'desc', align: 'start' },
    ]
    const taskHeaders = [
      { text: 'Task', value: 'task', align: 'start' },
      { text: 'Description', value: 'desc', align: 'start' },
    ]

    const saveTaskSelection = item => {
      console.log(item)
      selectedTask.value = item

      taskPanel.value = 1
    }

    const bindingData = computed(() => {
      const bindingDefinition = []

      const getBindings = gongBindingSendCallData
      getBindings.forEach(binding => {
        binding.values.forEach(value => {
          bindingDefinition.push({ ...value, header: binding.header })
        })
      })
      console.log('bindingDefinition', bindingDefinition)

      return bindingDefinition
    })

    watch(sqlQuery, to => {
      if (to === executedQuery.value) queryChanged.value = false
      else queryChanged.value = true
    })

    const buildTable = rows => {
      if (!rows || rows.length === 0) {
        headers.value = []
        queryResults.value = []
        alert.value = { type: 'info', message: 'Your query returned 0 results' }

        return
      }
      let headerRows = Object.keys(rows[0])
      headerRows = headerRows.map(m => ({ text: m, value: m }))
      headers.value = headerRows
      queryResults.value = rows
      if (headerRows.length > 0) queryChanged.value = false
    }

    const validateQuery = async () => {
      alert.value = {}
      queryError.value = null
      limitEnforced.value = false
      headers.value = []
      queryResults.value = []
      queryLoading.value = true
      executedQuery.value = sqlQuery.value
      let query = sqlQuery.value.replace(';', '')
      if (!query.toLowerCase().includes('limit')) {
        query += ' limit 10;'
        limitEnforced.value =
          'We limited your preview results to 10 rows. If you need to preview more rows use "LIMIT" in your query'
      } else {
        limitEnforced.value =
          'You are using "LIMIT" in your query and this will limit your final results. If this is for previewing only make sure to remove the "LIMIT" clause before moving on.'
      }
      try {
        const response = await snowflakeQuery(query)
        queryLoading.value = false

        if (typeof response === 'string') {
          alert.value = { type: 'error', message: response }

          return
        }
        buildTable(response)
      } catch (err) {
        alert.value = { type: 'error', message: err }
        queryError.value = err
        queryLoading.value = false
      }
    }

    const buildEditData = async () => {
      // Get Binding Object from Firestore
      try {
        const response = await readDoc({ collection: 'snowflakeBindings', docId: route.value.params.id })
        console.log('bindings', response)

        // Get Task Data
        connectors.forEach(connector => {
          let tasks = []
          if (connector.ingressTasks) tasks = tasks.concat(connector.ingressTasks)
          if (connector.egressTasks) tasks = tasks.concat(connector.egressTasks)
          tasks = tasks.filter(f => f.id === response.taskId)
          if (tasks.length > 0) {
            selectedConnector.value = connector
            // eslint-disable-next-line prefer-destructuring
            selectedTask.value = tasks[0]
          }
        })

        // Set Up Existing Variables
        snowBindingName.value = response.name
        sqlQuery.value = response.query
        docId.value = response.id
        editBindingObject.value = response
      } catch (err) {
        const errorMessage = `Could not get saved binding data: ${err}`
        throw errorMessage
      }
    }

    onMounted(() => {
      if (route.value.name.includes('edit-binding')) {
        isEdit.value = true
        console.log(`Getting Binding Data For: ${route.value.params.id}`)
        currentTab.value = 1
        buildEditData()
      }
    })

    return {
      editBindingObject,
      isEdit,
      ingressTaskSelectionIndex,
      egressTaskSelectionIndex,
      saveTaskSelection,
      taskPanel,
      selectedTask,
      taskHeaders,
      connectors,
      docId,
      snowBindingName,
      snowBindingTask,
      bindingHeaders,
      bindingData,
      alert,
      queryChanged,
      headers,
      buildTable,
      limitEnforced,
      queryError,
      queryResults,
      queryLoading,
      validateQuery,
      sqlQuery,
      currentTab,
      selectedConnector,

      icons: {
        mdiAccountOutline,
        mdiLockOutline,
        mdiAccessPoint,
        mdiCogTransfer,
        mdiTransitConnectionVariant,
        mdiTransitConnectionHorizontal,
        mdiTableEdit,
        mdiFileTableBox,
        mdiCheckboxMarkedCircleOutline,
        mdiDatabaseArrowLeftOutline,
        mdiDatabaseArrowRightOutline,
        mdiSquareEditOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  white-space: nowrap;
}
.task-selected {
  background-color: orange;
}
</style>
