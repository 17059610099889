exports.gongBindingSendCallData = [
  {
    header: 'Client Unique Id',
    id: 'clientUniqueId',
    values: [
      {
        name: 'clientUniqueId',
        required: true,
        unique: true,
        type: 'String',
        desc:
          "A call's unique identifier in the PBX or the recording system. Gong uses this identifier to prevent repeated attempts to upload the same recording.",
        binding: null,
      },
    ],
  },

  {
    header: 'Actual Start',
    id: 'actualStart',
    values: [
      {
        name: 'actualStart',
        required: true,
        type: 'String ISO-8601',
        desc:
          "The actual date and time when the call started in the ISO-8601 format (e.g., '2018-02-18T02:30:00-07:00' or '2018-02-18T08:00:00Z', where Z stands for UTC)",
        binding: null,
      },
    ],
  },
  {
    header: 'Call Direction',
    id: 'direction',
    values: [
      {
        name: 'direction',
        required: true,
        type: 'Conference, Inbound, Outbound or Unknown',
        desc:
          'Allowed: [Conference, Inbound, Outbound, Unknown]  Whether the call is inbound (someone called the company), outbound (a rep dialed someone outside the company), or a conference call.',
        binding: null,
      },
    ],
  },
  {
    header: 'Download Media URL',
    id: 'downloadMediaUrl',
    values: [
      {
        name: 'downloadMediaUrl',
        type: 'String',
        required: true,
        unique: true,
        desc:
          'The URL from which Gong can download the media file. The URL must be unique, the audio or video file must be a maximum of 1.5GB.',
        binding: null,
      },
    ],
  },
  {
    header: 'Title',
    id: 'title',
    values: [
      {
        name: 'title',
        type: 'String',
        desc: 'The title of the call. This title is available in the Gong system for indexing and search.',
        binding: null,
      },
    ],
  },
  {
    header: 'Purpose',
    id: 'purpose',
    values: [
      {
        name: 'purpose',
        type: 'String',
        desc: 'The purpose of the call. This optional field is a free text of up to 255 characters.',
        binding: null,
      },
    ],
  },
  {
    header: 'Internal Party',
    id: 'partiesInternal',
    headerDesc: 'Provide Information for the Internal (Team Member) party of the provided call.',
    values: [
      {
        name: 'name',
        text: 'Name',
        required: true,
        type: 'String',
        desc: 'Name of the internal party member or team member',
        binding: null,
      },
      {
        name: 'userId',
        text: 'User Id',
        required: true,
        unique: true,
        type: 'String',
        desc:
          'The user ID of the participant within the Gong system the GongId, if the participant is a user. Disabled if using the users email.',
        binding: null,
      },
      {
        name: 'phoneNumber',
        text: 'Phone Number',
        required: true,
        unique: true,
        type: 'String',
        desc: 'The phone number of the internal party, if available.',
        binding: null,
      },
      {
        name: 'emailAddress',
        text: 'Email',
        type: 'String',
        desc: 'The email address of the internal party, if available.',
        binding: null,
      },
    ],
  },
  {
    header: 'External Party',
    id: 'partiesExternal',
    headerDesc: 'Provide Information for the External party of the provided call.',
    values: [
      {
        name: 'name',
        text: 'Name',
        type: 'String',
        desc: 'Name of the External Party',
        binding: null,
      },
      {
        name: 'userId',
        text: 'User Id',
        type: 'String',
        desc: 'A unique identifier for the external party, if available',
        binding: null,
      },
      {
        name: 'phoneNumber',
        text: 'Phone Number',
        required: true,
        unique: true,
        type: 'String',
        desc: 'The phone number of the external party, if available.',
        binding: null,
      },
      {
        name: 'emailAddress',
        text: 'Email',
        type: 'String',
        desc: 'The email address of the external party, if available.',
        binding: null,
      },
    ],
  },
]
